import real from '../../img/Real.jpg';

function RealEstate() {
  return(
    <div className="container body-container">
        <div className="events">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 d-flex justify-content-center">
                        <img src={real} alt="" width="700" height="480" className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className= "col-md-10 offset-md-1">
                        <h2 align="center">Real Estate Law</h2>
                        <h5 align="justify"> At Hainer &amp; Berman, we represent a diverse group of real estate developers, property managers, builders, construction companies and corporate clients who require assistance with real estate transactions. Our experience encompasses
                        the full spectrum of real estate transactions, from simple land purchases and leases to complex real estate developments. We have represented clients in transactions for the sale, purchase or development of hotels, apartment buildings,
                        office and industrial buildings, energy complexes and retail centers. In addition, we have a unique expertise in the development of golf course/residential communities, including Prestwick Village in Michigan and Lake Jovita Golf
                        and Country Club in Florida. Finally, we have extensive experience in real estate and construction litigation and arbitration proceedings. <br /><br />
                        </h5>
                        <h5 align="center">
                        Residential and Commercial Developments<br /> Attached and Site Condominium Developments<br /> Golf Course Developments<br /> Construction Contracts<br /> Construction and Mortgage Financing<br /> Construction and Real Estate Litigation<br />                        Leasing
                        <br /> Acquisitions
                        <br />
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default RealEstate;
