import business from '../../img/business.jpg';

function Business() {
  return(
    <div className="container body-container">
        <div className="events">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 d-flex justify-content-center">
                        <img src={business} alt="" width="700" height="480" className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className= "col-md-10 offset-md-1">
                        <h2 align="center">Business Law </h2>
                        <h5 align="justify"> At Hainer &amp; Berman, we offer a wide range of services to suit the needs of businesses of all sizes: from publicly-held companies to closely-held corporations, partnerships and limited liability companies. We serve as counsel to energy
                            companies, banks and other financial institutions, retail companies, construction companies, automotive suppliers, as well as a number of companies in a variety of other service industries. We help clients meet their business goals
                            and advise them on day-to-day legal matters, including, operating agreements, partnership agreements, buy-sell agreements, employment agreements, non-competition and non-solicitation and non-compete agreements. We also have extensive
                            experience in the mergers and acquisitions area, having handled transactions in virtually every industry. Our attorneys have also been involved in numerous stock and asset acquisitions, private placements and financings transactions
                            for our clients. <br /><br />
                        </h5>
                        <h5 align="center">
                            Mergers and Acquisitions<br /> General Business Consultation<br /> Formation of Corporations, Partnerships and Limited Liability Companies<br /> Private Placements<br /> Franchise Law<br />
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Business;
