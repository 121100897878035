function Clients() {
  return(
    <div>
        <div className="breadcrumb d-flex justify-content-center">
          <h2>Representative Clients</h2>
        </div>
        <div className="container body-container events">
            <div className="col-md-10 offset-md-1">
                <h5 align="justify">
                    <strong>
                        Hainer &amp; Berman represents numerous entrepreneurs and high net worth individuals,
                        acts as general counsel for many privately held companies, and also provides services for larger, publicly-held
                        companies. A representative sample of clients is set forth below:
                    </strong>
                </h5>
                <h5 align="center" className="margin-20">
                    Aria Energy, Inc. <br /> ABC Warehouse<br /> Automark International Inc.<br /> Beachum & Roeser Development and Management Corporation<br /> Big Boy Restaurants Franchisees<br /> Chemical Bank<br /> Corvias, LLC <br /> Cunningham-Limp Development
                    Company
                    <br /> DTE Biomass Energy, Inc.<br /> DTE Energy Services, Inc.<br /> Energy Fundamentals Group, LLC<br /> Energy Transfer Partners<br /> Gentz Industries, Inc.<br /> The Great Lakes Coffee Roasting Company <br /> Hazen Transport, Inc.<br /> K.C.
                    Jones Plating Co.<br /> Lake Jovita Development Corporation<br /> mBank
                    <br /> M & O Marketing, Inc.<br /> Philip Stevens Building Company<br /> Prestwick Village<br /> Visioneering, Inc.<br /> UHY Advisors, Inc.
                </h5>
            </div>
        </div>
    </div>
  );
}

export default Clients;
