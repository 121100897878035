import im3 from '../../img/3.jpg';
import vcf from '../../img/KayMalaney.vcf';
import bio from '../../img/KayMalaneyDownload.pdf';

function Malaney() {
  return(
    <div className="container body-container">
        <div className="row team-row">
            <div className="col-md-4">
                <img src={im3} alt="" className="img-responsive" />
            </div>
            <div className="col-md-8">
                <h2>Kay Malaney</h2>
                <h5><a href="mailto:kmalaney@hainerberman.com">kmalaney@hainerberman.com</a> &nbsp; • &nbsp; <a href={vcf} target="_blank">V-Card</a> &nbsp; • &nbsp; <a href={bio} target="_blank">Print
					Bio</a></h5><br />
                <h5 align="justify"> Kay Malaney received a Bachelor of Science and nursing degree from the University of Wisconsin-Madison in 1978 and was employed as a registered nurse, specializing in neonatology prior to going to law school. In 1986 she graduated
                from Wayne State University Law School. She was a Bronze Key award winner and a member of Moot Court. Prior to joining Hainer &amp; Berman, Kay was a partner at Bodman, LLP. She is admitted to practice in the state courts of Michigan as
                well as the U.S. District Courts for the Eastern and Western Districts of Michigan. She is a member of the Oakland County Bar Association and the Family Law, Litigation and Probate and Estate Planning Sections of the State Bar of Michigan.<br />
                <br /> Kay lives in a cottage on a lake in Oakland County, has two daughters, five grandchildren, and a Siberian husky. She enjoys golf, skiing and rollerblading and loves to travel.
                </h5>
            </div>
        </div>
    </div>
  );
}

export default Malaney;
