import civil from '../../img/civil.jpg';

function Civil() {
  return(
    <div className="container body-container">
        <div className="events">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 d-flex justify-content-center">
                        <img src={civil} alt="" width="700" height="480" className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className= "col-md-10 offset-md-1">
                        <h2 align="center">Civil Litigation</h2>
                        <h5 align="justify"> We represent clients in a wide range of commercial litigation matters in federal and state courts. At Hainer &amp; Berman, our litigation practice has been successful for several reasons. First, we determine our clients’ objective at the
                    outset and litigate accordingly. If our clients desire a quick settlement, we work to achieve that result. When a trial is expected, we litigate aggressively on behalf of our clients. Second, we keep our clients fully informed throughout
                    the litigation process to avoid any surprises. Third, we litigate in a cost effective manner, ever mindful of the balance between litigation expense and the anticipated recovery.<br /><br />
                        </h5>
                        <h5 align="center">
                        Federal and State Court Litigation<br /> Alternative Dispute Resolution Proceedings<br /> Commercial and Business Litigation<br /> Insurance Litigation<br />
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Civil;
