import environmental from '../../img/environmental.jpg';

function Energy() {
  return(
    <div className="container body-container">
        <div className="events">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 d-flex justify-content-center">
                        <img src={environmental} alt="" width="700" height="480" className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className= "col-md-10 offset-md-1">
                        <h2 align="center">Energy &amp; Environmental Law</h2>
                        <h5 align="justify"> Our attorneys have substantial experience in the landfill gas to renewable energy projects, power generator projects and energy services agreements, serving clients with projects throughout the United States since the mid-1990s. Such
                        experience includes projects in the public and private sectors, as well as development activity in the power and industrial field, including the development and management of on-site services for clients operating in the chemical,
                        automotive and consumer products sectors. In addition, we have assisted clients with projects including universities and municipal airports. Finally, we have handled numerous acquisitions of companies and projects in the landfill
                        gas to renewable energy space. Our attorneys also have substantial experience in advising clients regarding environmental issues relating to lending, real estate and business transactions. Finally, our attorneys have represented
                        companies in the hazardous waste, waste hauling and landfill industries. <br /><br />
                        </h5>
                        <h5 align="center">
                        Development of Landfill Gas to Energy Projects<br /> Mergers, Acquisitions and Joint Ventures for Biogas Projects<br /> Power Generator Projects<br /> Environmental Counseling for Business/Real Estate Acquisitions<br /> Baseline Environmental
                    Assessments and Due Diligence Reviews<br />
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Energy;
