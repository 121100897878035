import im1 from '../../img/1.jpg';

function Hainer() {
  return(
    <div className="container body-container">
        <div className="row team-row">
            <div className="col-md-4">
                <img src={im1} alt="" className="img-responsive" />
            </div>
            <div className="col-md-8">
                <h2>Mike Hainer</h2>
                <h5 align="justify">(1956-2020)<br /> So Much to So Many
                    <br />
                    <br /> In namesake, personality, diligence and a dedication to excellence, our firm will continue to honor the teachings and ethics of Mike Hainer, a dedicated servant to all. <br />
                    <br />Foremost, Mike was a family man and generous with his time. Mike was married to his high school sweetheart, Sue. Together, they raised three beautiful daughters and enjoyed five&nbsp;grandchildren.<br />
                    <br />Mike attended the University of Michigan on the Evans Scholarship, graduating in 1978 with a B.A., with High Distinction and Honors in Economics. He graduated Cum Laude from the University of Michigan Law School in 1983. While in law
                    school, he served as a Note Editor of the Michigan Law Review.<br />
                    <br />After law school, Mike was a law clerk to the Honorable Richard P. Matsch, U.S. District Judge in Denver, Colorado. Mike founded this firm in 1990. He was trusted by many clients and friends for over 30 years as a confidant, sounding
                    board, and intellectually sound advisor, with a keen business sense and impeccable ethics.
                </h5>
            </div>
        </div>
    </div>
  );
}

export default Hainer;
