import table2 from '../img/table2.jpg';

function Contact() {
  return(
    <div>
        <div className="breadcrumb d-flex justify-content-center">
          <h2>Contact</h2>
        </div>

        <div className="container body-container">
            <div className="row">
                <div className="col-md-6">
                    <img src={table2} alt="" width="1920" height="1080" className="img-fluid" />
                </div>
                <div className="col-md-6">
                    <div className="recent">
                        <h3><br /><br />Contact Us</h3>
                    </div>
                    <div>
                        <h4>Address:</h4>24255 West Thirteen Mile Road, Suite 270<br /> Bingham Farms, Michigan 48025<br /><br />
                        <h4>Telephone: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Fax: </h4>
                        <p>248-642-4100 &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 248-642-4101</p>
                        <br />
                        <h4>Emails:</h4>
                        <p style={{fontSize: "1.3em"}}> Michael Hainer - <a href="mailto:mhainer@hainerberman.com">mhainer@hainerberman.com</a>
                            <br /> Leonard Berman - <a href="mailto:lenberman@hainerberman.com">lenberman@hainerberman.com</a>
                            <br /> Kay Malaney - <a href="mailto:kmalaney@hainerberman.com">kmalaney@hainerberman.com</a>
                            <br /> Amy Szostek -<a href="mailto:aszostek@hainerberman.com"> aszostek@hainerberman.com</a>
                            <br /> Tracy Hainer - <a href="mailto:thainer@hainerberman.com">thainer@hainerberman.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="mapouter margin-20">
                    <div className="gmap_canvas">
                        <iframe width="1080" height="380" id="gmap_canvas" src="https://maps.google.com/maps?q=24255%20W%2013%20Mile%20Rd%2C%20Bingham%20Farms%2C%20MI%2048025&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no"
                            marginheight="0" marginwidth="0">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Contact;
