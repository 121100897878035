import probate from '../../img/probate.jpg';

function Probate() {
  return(
    <div className="container body-container">
        <div className="events">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 d-flex justify-content-center">
                        <img src={probate} alt="" width="700" height="480" className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className= "col-md-10 offset-md-1">
                        <h2 align="center">Estate Planning &amp; Probate Law</h2>
                        <h5 align="justify"> At Hainer &amp; Berman, we advise clients regarding all aspects of estate planning and estate administration. Our attorneys have extensive experience in drafting wills, revocable trusts, irrevocable life insurance trusts, special needs
                        trusts, family limited liability companies, family limited partnerships and other instruments relating to the lifetime or testamentary transfer of assets. We represent and counsel clients (including estates, trusts and beneficiaries)
                        with respect to a variety of probate matters, including will contests and related litigation. In addition, we advise clients regarding estate and succession planning for family and closely-held businesses, and we prepare buy/sell
                        and similar agreements for family and closely-held businesses. We also assist clients with retirement planning, including distributions from qualified pension plans and IRAs.<br /><br />
                        </h5>
                        <h5 align="center">
                        Estate Planning<br /> Family Limited Partnerships<br /> Succession Planning for Closely-Held Businesses<br /> Charitable Trusts<br /> Retirement Planning<br /> Probate Administration and Litigation<br />
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Probate;
