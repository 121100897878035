import labor from '../../img/labor.jpg';

function Labor() {
  return(
    <div className="container body-container">
        <div className="events">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 d-flex justify-content-center">
                        <img src={labor} alt="" width="700" height="480" className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className= "col-md-10 offset-md-1">
                        <h2 align="center">Labor &amp; Employment Law</h2>
                        <h5 align="justify"> Employment issues arise almost daily in business. Hainer &amp; Berman understands the need to develop practical solutions to these issues. We counsel clients on numerous employment issues that arise day-to-day. We help clients establish
                        policies and procedures through employee handbooks, employment applications and employment practices that are designed to avoid problems in the work place. To the extent problems develop, we help clients address those problems
                        short of adversarial proceedings. When litigation becomes necessary, we vigorously represent our clients in all types of employment litigation. In addition, we assist in matters before the EEOC and MDCR. We also draft and enforce
                        non-compete and non-solicitation agreements for our clients. <br /><br />
                        </h5>
                        <h5 align="center">
                        Selection and Hiring Procedures<br /> Wage and Salary Issues<br /> Sexual Harassment<br /> Discrimination Claims<br /> Compliance Issues (ADA, FMLA, OSHA, MiOSHA)<br /> Wrongful Discharge<br /> Executive Severance and Termination<br />
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Labor;
