import '../css/home.css';

function Footer() {
  return(
    <footer>
        <div className="inner-footer">
            <div className="container footer-container">
                <div className="row">
                    <div className="col-md-4 f-about">
                        <p> <strong> CONTACT </strong> <br />
                            <br /> p: 248-642-4100 &nbsp; &nbsp; &nbsp; f: 248-642-4101</p>
                        <p>24255 West Thirteen Mile Road, Suite 270 <br /> Bingham Farms, Michigan 48025</p>
                    </div>
                    <div className="col-md-4 f-about">
                    </div>
                    <div className="col-md-4 f-contact">
                    </div>
                </div>
            </div>
        </div>

        <div className="last-div">
            <div className="container">
                <div className="row">
                    <div className="copyright">
                        <p><a href="index.html">Hainer & Berman, P.C. ©2018 |</a> <a href="http://www.silecchia.com/" target="_blank">Web
                Design Silecchia Inc.</a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}

export default Footer;
