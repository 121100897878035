import family from '../../img/family.jpg';

function Family() {
  return(
    <div className="container body-container">
        <div className="events">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 d-flex justify-content-center">
                        <img src={family} alt="" width="480" height="700" className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className= "col-md-10 offset-md-1">
                        <h2 align="center">Family Law</h2>
                        <h5 align="justify"> The practice of family law requires superior legal expertise and compassion. At Hainer &amp; Berman, we have both. Our attorneys understand the issues and emotions involved in this area of practice and work closely with our clients
                        to achieve excellent results. We handle matters in Wayne, Oakland, Macomb and surrounding Michigan counties. Moreover, our experience in business law and general litigation enables us to handle complex divorce cases involving business
                        valuations and asset tracing. <br /><br />
                        </h5>
                        <h5 align="center">
                        Divorce<br /> Child Custody<br /> Child and Spousal Support<br /> Parenting and Grand Parenting Time<br /> Paternity
                        <br /> Pre-Marital Planning<br />
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Family;
