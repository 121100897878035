import im2 from '../../img/2.jpg';
import vcf from '../../img/LeonardBerman.vcf';
import bio from '../../img/LenBermanDownload.pdf';

function Berman() {
  return(
    <div className="container body-container">
        <div className="row team-row">
            <div className="col-md-8">
                <h2>Len Berman</h2>
                <h5><a href="mailto:lenberman@hainerberman.com">lenberman@hainerberman.com</a> • &nbsp; 248-877-8126 &nbsp; • &nbsp;
                    <a href={vcf} target="_blank">V-Card</a> &nbsp; • &nbsp; <a href={bio} target="_blank">Print Bio</a></h5><br />
                <h5 align="justify"> Len Berman graduated from MSU in 1985 with a B.A. in Business Administration. Len attended Wayne State University Law School, graduating Cum Laude in 1988. While in law school, Len served on the Law Review as an Assistant Editor, 1986-1987
                    and Associate Editor, 1987-1988. Upon graduation, Len was awarded membership in the Order of the Coif, an honorary fraternity of individuals graduating in the top of their law school class. <br />
                    <br /> Prior to joining Hainer &amp; Berman, Len was an associate at Bodman LLP, and counsel at Elias Brothers Restaurants, Inc. Len joined the Michigan Bar in 1988 and is admitted to practice in the state courts of Michigan as well as U.S.
                    District Courts for the Eastern and Western Districts of Michigan and the Northern District of Illinois. Len is a member of the Oakland County and American Bar Associations as well as the State Bar of Michigan.
                    <br />
                    <br /> Len lives in Novi with his wife, Sharon, and their daughters, Sarah and Rebecca and son, Joseph. When not practicing law, Len plays golf and enjoys spending time with his family.<br />
                    <br /> Len is actively involved in the WGA-Evans Scholars Foundation. Len is also a two-time Past President of Meadowbrook Country Club and an Elder at Faith Community Presbyterian Church in Novi. </h5>
            </div>
            <div className="col-md-4">
                <img src={im2} alt="" className="img-responsive" />
            </div>
        </div>
    </div>
  );
}

export default Berman;
