import bankruptcy from '../../img/bankruptcy.jpg';

function Banking() {
  return(
    <div className="container body-container">
        <div className="events">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 d-flex justify-content-center">
                        <img src={bankruptcy} alt="" width="480" height="700" className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className= "col-md-10 offset-md-1">
                        <h2 align="center">Banking &amp; Creditor's Rights</h2>
                        <h5 align="justify"> Hainer &amp; Berman represents banks, financial institutions and a number of other clients in connection with the structuring, negotiating and documenting secured and unsecured commercial loans, letters of credit and other credit facilities
                        including real estate lending transactions. We have represented banks, financial institutions and other secured and unsecured creditors in out-of-court workouts and in Chapter 11 and Chapter 7 bankruptcy proceedings. Our experience
                        also includes handling evictions, land contract forfeitures, and real and personal property liquidations. In addition, we have handled receivership matters in state court and litigated numerous commercial transaction cases, including
                        those involving fraudulent transfers. <br /><br />
                        </h5>
                        <h5 align="center">
                        Business Loan Transactions<br /> Real Estate Loan Transactions<br /> Asset-Based Lending Transactions<br /> Workouts
                        <br /> Eviction Proceedings<br /> Land Contract Forfeiture Proceedings<br /> Bankruptcy Proceedings<br /> Receivership Proceedings<br /> Creditor’s Rights
                        <br />
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Banking;
