import { useEffect, useState } from 'react';
import hbgold from '../img/hbgold.png';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '../css/navigation.css';

function Navigation() {
  const [active, setActive] = useState('home');

  useEffect(() => {
    changeActive();
  })

  function changeActive() {
    const pathname = window.location.pathname;
    console.log(pathname)
    // these list correspond to the links below, so that when one is selected, the correct tab is active
    const services = ['/energy', '/realestate', '/labor', '/banking', '/probate', '/civil', '/family'];
    const team = ['/hainer', '/berman', '/malaney', '/ourstory'];

    if (pathname == '/' || pathname == '') {
      setActive('home');
    }
    else if (services.includes(pathname)) {
      setActive('services');
    }
    else if (team.includes(pathname)) {
      setActive('team');
    }
    else if (pathname == '/clients') {
      setActive('clients');
    }
    else if (pathname == '/contact') {
      setActive('contact');
    }
  }

  return(
    <nav className="navbar navbar-expand-lg zero-top-padding sticky-top">
        <div className="container">
            <div className="navbar-header zero-top-padding">
                <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <FontAwesomeIcon icon={faBars} />
        				</button>
                <Link className="navbar-brand" to="/"><span><img src={hbgold} alt="" height="80" /></span></Link>
            </div>

            <div className="navbar-collapse collapse" id="navbarSupportedContent">
                <div className="menu" onClick={changeActive}>
                    <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className={ active == 'home' ? 'active' : '' }>
                            <Link to="/">HOME</Link>
                        </li>

                        <div className={ active == 'services' ? 'dropdown active' : 'dropdown' }>
                            <button className="dropbtn">OUR SERVICES</button>
                            <div className="dropdown-content">
                                <li role="presentation" className="active"><Link to="/business">Business Law</Link></li>
                                <li role="presentation"><Link to="/energy">Energy & Environmental Law</Link></li>
                                <li role="presentation"><Link to="/realestate">Real Estate Law</Link></li>
                                <li role="presentation"><Link to="/labor">Labor & Employment Law</Link></li>
                                <li role="presentation"><Link to="/banking">Banking & Creditor's Rights</Link></li>
                                <li role="presentation"><Link to="/probate">Estate Planning & Probate Law</Link></li>
                                <li role="presentation"><Link to="/civil">Civil Litigation</Link></li>
                                <li role="presentation"><Link to="/family">Family Law</Link></li>
                            </div>
                        </div>

                        <div className={ active == 'team' ? 'dropdown active' : 'dropdown' }>
                            <button className="dropbtn">OUR TEAM</button>
                            <div className="dropdown-content">
                                <li role="presentation" className="active"><Link to="/hainer">Mike Hainer</Link></li>
                                <li role="presentation"><Link to="/berman">Len Berman</Link></li>
                                <li role="presentation"><Link to="/malaney">Kay Malaney</Link></li>
                                <li role="presentation"><Link to="/ourstory">Staff</Link></li>
                            </div>
                        </div>

                        <li role="presentation" className={ active == 'clients' ? 'dropdown active' : '' }>
                            <Link to="/clients">OUR CLIENTS</Link>
                        </li>

                        <li role="presentation" className={ active == 'contact' ? 'dropdown active' : '' }>
                            <Link to="/contact">CONTACT US</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
  );
}

export default Navigation;
