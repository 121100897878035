import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/bootstrap-overwrite.css';
import './css/animate.min.css';
import Router from './components/router.js';

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
);
