import { useEffect } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import WOW from 'wowjs';
import im9 from '../img/9.jpg';
import im6 from '../img/6.jpg';
import im7 from '../img/7.jpg';
import im8 from '../img/8.jpg';
import im112 from '../img/112.jpg';
import im122 from '../img/122.jpg';
import collage from '../img/collage.jpg';
import '../css/home.css';

const images = [
  { url: im9 },
  { url: im6 },
  { url: im7 },
  { url: im8 }
];

function Home() {

  useEffect(() => {
    new WOW.WOW({
        live: false
    }).init();
  });

  return(
    <div>
        <div className="container slider-container">
            <SimpleImageSlider
                width="80vw"
                height="90vh"
                images={images}
                showBullets={true}
                showNavs={true}
                autoPlay={true}
                autoPlayDelay={5}
              />
          </div>
          <div className="container body-container">
            <div className="jumbotron col-lg-12">
                <h1 className="col-lg-12 emphasized-text" align="justify">
                    <em>
                        The mission of Hainer & Berman is to understand the legal needs of our
    				            clients and to provide them with practical, cost-effective solutions.
                    </em>
                </h1>
                <h3 className="col-lg-12" align="justify">
                    <p className="home-text">
                        Hainer &amp; Berman, P.C. is a business law firm that provides a wide variety of legal services to its clients. Mike Hainer founded the firm in 1990, and Len Berman became a partner in 1996. In addition to excellent academic credentials, our lawyers
                        have over 110 years of combined legal experience. Our lawyers, many of whom trained at Detroit’s largest law firms, decided that they prefer the working environment of a boutique law firm to that of a large organization. The firm is AV rated
                        and listed in Martindale-Hubbell’s Bar Register of Preeminent Lawyers.
                    </p>
                    <p className="home-text">
                        In addition to our superior qualifications, Hainer &amp; Berman is different in many other respects. Unlike many firms our size, we routinely handle large, complex legal matters—all while providing a personal touch that may be missing
                        with other, larger law firms. We pride ourselves on being responsive and thorough. We care about our clients, having known many of them for over 20 years. Most importantly, we attain good results. Work with our attorneys, and you will notice
                        a decided difference.
                    </p>
                </h3>
            </div>

            <div className="row padding-top-60">
                <div className="col-md-6">
                    <img src={im122} alt="" className="img-fluid full-img" />
                </div>
                <div className="col-md-6">
                    <img src={im112} alt="" className="img-fluid full-img" />
                </div>
            </div>
        </div>

        <section className="box">
            <div className="container body-container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="wow bounceIn" data-wow-offset="0" data-wow-delay="0.4s">
                            <div className="services">
                                <div className="icons"> </div>
                                <h4>Business Law</h4>
                                <p>
                                    • Mergers and Acquisitions <br /> • General Business Consultation<br /> • Formation of Corporations, Partnerships <br />and Limited Liability Companies<br /> • Private Placements<br /> • Franchise Law<br /><br /><br />
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="col-md-4">
                        <div className="wow bounceIn" data-wow-offset="0" data-wow-delay="0.4s">
                            <div className="services">
                                <div className="icons"> </div>
                                <h4>Energy &amp; Environmental Law</h4>
                                <p>
                                    • Development of Landfill Gas to Energy Projects <br /> • Mergers, Acquisitions and <br />Joint Ventures for Biogas Projects<br /> • Power Generator Projects<br /> • Environmental Counseling for <br />Business/Real Estate Acquisitions<br />                                • Baseline Environmental Assessments and <br />Due Diligence Reviews<br />
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="col-md-4">
                        <div className="wow bounceIn" data-wow-offset="0" data-wow-delay="1.2s">
                            <div className="services">
                                <div className="icons"> </div>
                                <h4>Real Estate Law</h4>
                                <p>
                                    • Residential and Commercial Developments <br /> • Attached and Site Condominium Developments<br /> • Golf Course Developments<br /> • Construction Contracts<br /> • Construction and Mortgage Financing<br /> • Construction and Real
                                    Estate Litigation<br /><br /><br />
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="wow bounceIn" data-wow-offset="0" data-wow-delay="1.2s">
                            <div className="services">
                                <div className="icons"> </div>
                                <h4>Labor &amp; Employment Law</h4>
                                <p>
                                    • Selection and Hiring Procedures<br /> • Wage and Salary Issues<br /> • Sexual Harassment <br /> • Wrongful Discharge<br /> • Discrimination Claims<br /> • Compliance Issues <small>(ADA, FMLA, OSHA, MiOSHA) </small><br /> • Executive
                                    Severance and Termination<br /><br /><br />
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="col-md-4">
                        <div className="wow bounceIn" data-wow-offset="0" data-wow-delay="1.2s">
                            <div className="services">
                                <div className="icons"> </div>
                                <h4>Banking &amp; Creditor's Rights</h4>
                                <p>
                                    • Business Loan Transactions <br /> • Real Estate Loan Transactions<br /> • Asset-Based Lending Transactions<br /> • Workouts<br /> • Eviction Proceedings<br /> • Land Contract Forfeiture Proceedings<br /> • Bankruptcy Proceedings<br />                                • Receivership Proceedings<br /> • Creditor’s Rights<br />
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="col-md-4">
                        <div className="wow bounceIn" data-wow-offset="0" data-wow-delay="0.8s">
                            <div className="services">
                                <div className="icons"> </div>
                                <h4>Estate Planning &amp; Probate Law</h4>
                                • Estate Planning • Family Limited Partnerships • Succession Planning for Closely-Held Businesses • Charitable Trusts • Retirement Planning • Probate Administration and Litigation<br /><br /><br /><br /><br /><br /><br /><br />
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="col-md-4">
                        <div className="wow bounceIn" data-wow-offset="0" data-wow-delay="1.2s">
                            <div className="services">
                                <div className="icons"> </div>
                                <h4>Civil Litigation</h4>
                                <p>
                                    • Federal and State Court Litigation <br /> • Alternative Dispute Resolution Proceedings<br /> • Commercial and Business Litigation<br /> • Insurance Litigation<br /><br /><br />
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="col-md-4">
                        <div className="wow bounceIn" data-wow-offset="0" data-wow-delay="0.8s">
                            <div className="services">
                                <div className="icons"> </div>
                                <h4>Family Law</h4>
                                <p>
                                    • Divorce <br /> • Child Custody<br /> • Child and Spousal Support<br /> • Parenting and Grand Parenting Time<br /> • Paternity<br /> • Pre-Marital Planning<br />
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
        </section>

        <div className="container body-container">
            <div className="slider">
                <div className="img-fluid">
                    <img src={collage} alt="" className="img-fluid" />
                </div>
            </div>
        </div>
    </div>
  );
}

export default Home;
